import React, { useState, useEffect } from 'react'
import { CampaignContext } from './index'

const CampaignContextProvider = ({ children, value }) => {
	const [campaignAstreaValue, setCampaignAstreaValue] = useState(value || '')
	const [campaignThemisValue, setCampaignThemisValue] = useState(value || '')

	useEffect(() => {
		let getItemSessionAstrea = localStorage.getItem('campaign_astrea:1.0.0')
		let getItemSessionThemis = localStorage.getItem('campaign_themis:1.0.0')

		const hasAstreaSessionItem =
			value !== getItemSessionAstrea && getItemSessionAstrea != null
		const hasThemisSessionItem =
			value !== getItemSessionThemis && getItemSessionThemis != null

		if (hasAstreaSessionItem) {
			setCampaignAstreaValue(getItemSessionAstrea)
		}

		if (hasThemisSessionItem) {
			setCampaignThemisValue(getItemSessionThemis)
		}
	}, [])

	function updateCampaignAstreaValue(value) {
		setCampaignAstreaValue(value)
	}

	function updateCampaignThemisValue(value) {
		setCampaignAstreaValue(value)
	}

	return (
		<CampaignContext.Provider
			value={{
				campaignAstrea: campaignAstreaValue,
				campaignThemis: campaignThemisValue,
				updateCampaignAstreaValue,
				updateCampaignThemisValue,
			}}
		>
			{children}
		</CampaignContext.Provider>
	)
}

export default CampaignContextProvider
